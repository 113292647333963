import { QueryKeys } from '@lobox/utils';
import type { SchedulesEventTypes } from '@lobox/utils';

const getSchedulesSectionsQueryKey = (
  schedulesEventType: SchedulesEventTypes
) => {
  const upComingQueryKey = [QueryKeys.getUpcomingMeetings, schedulesEventType];
  const pastQueryKey = [QueryKeys.getPastMeetings, schedulesEventType];

  return { pastQueryKey, upComingQueryKey };
};

export default getSchedulesSectionsQueryKey;
