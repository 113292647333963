import classes from './UnitSectionItemWithData.module.scss';

import Flex from '@lobox/uikit/Flex';
import cnj from '@lobox/uikit/utils/cnj';
import Skeleton from '@lobox/uikit/Skeleton';
import UnitSectionItem from '@shared/components/Organism/UnitSection/UnitSection.item';
import { useGetUserData } from '@shared/components/Organism/UnitSection/useGetUserData';
import React from 'react';

type Props = {
  username: string;
  className?: string;
  isPage?: boolean;
  useId?: boolean;
  fallbackData?: any;
  // fallbackData?: ComponentProps<typeof EntitiyPopper>['fallbackData'];
  hideActions?: boolean;
};

export const UnitSectionItemWithData: React.FC<Props> = (props) => {
  const { username, className, isPage, useId, fallbackData, hideActions } =
    props;

  const { data, isLoading } = useGetUserData(username, isPage, useId);

  return (
    <Flex className={cnj(classes.wrapper, className)}>
      {isLoading && !fallbackData ? (
        <SectionSkeleton />
      ) : (
        <UnitSectionItem
          item={{
            ...(data as Record<string, any>),
            actions: hideActions
              ? null
              : (data as Record<string, any>)?.actions,
          }}
          fallbackData={fallbackData}
          isLoading={isLoading}
        />
      )}
    </Flex>
  );
};

function SectionSkeleton(): JSX.Element {
  return (
    <Flex className={classes.skeletonWrapper}>
      <Skeleton className={classes.skeleton__1} />
      <Skeleton className={classes.skeleton__1} />
      <Skeleton className={classes.skeleton__2} />
      <Skeleton className={classes.skeleton__3} />
    </Flex>
  );
}
