import React from 'react';
import { useHistory } from '@lobox/utils';
import { preventClickHandler, routeNames, useTranslation } from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import Button from '@lobox/uikit/Button';
import ObjectLink from '@lobox/uikit/Link/ObjectLink';
import type { ComponentProps } from 'react';
import type { AvatarProps } from '@lobox/uikit/Avatar';
import ObjectCard from '../../molecules/ObjectCard';
import type { EntitiyPopper } from '@shared/components/molecules/EntityPopper/EntitiyPopper';

export interface UnitSectionItemProps {
  item: any;
  avatarProps?: AvatarProps;
  fallbackData?: ComponentProps<typeof EntitiyPopper>['fallbackData'];
  isLoading?: boolean;
  noLinkOnClick?: boolean;
}
const UnitSectionItem: React.FC<UnitSectionItemProps> = ({
  item,
  avatarProps: aP,
  fallbackData,
  isLoading,
  noLinkOnClick,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { getAppObjectPropValue, authUser } = useGetAppObject();
  const objectId = getAppObjectPropValue({ userKey: 'id', pageKey: 'id' });
  const ObjectLink = getAppObjectPropValue({
    userKey: 'username',
    pageKey: 'username',
  });

  const onClickPosts = (e: any) => {
    preventClickHandler(e);
    history.push(`/${item.username}${routeNames.feed}`);
  };

  let {
    id,
    image,
    title,
    subtitle,
    description,
    notification,
    followers,
    following,
    actions,
    avatarProps,
    to,
    href,
    onClick,
    moreList,
    isBlocked,
    undoBlock,
    remove,
    pageRole,
    ownerId,
    croppedHeaderImageLink,
    postsCounter,
    locationTitle,
    isPrivateAccount,
    objectUsername,
  } = item;
  if (isLoading && fallbackData) {
    // fill from the fallback in case these were not present
    if (!image) image = fallbackData?.icon;
    if (!title) title = fallbackData?.name;
    if (!subtitle) subtitle = fallbackData?.usernameAtSign;
  }

  return (
    <ObjectCard
      image={image}
      postsCounter={postsCounter}
      locationTitle={locationTitle}
      croppedHeaderImageLink={croppedHeaderImageLink}
      avatarProps={{ ...aP, ...avatarProps }}
      id={id}
      title={title}
      subtitle={subtitle}
      description={description}
      notification={notification}
      followers={followers}
      following={following}
      actions={
        objectId === id ? (
          <ObjectLink username={objectUsername} objectId={objectId}>
            <Button
              fullWidth
              schema="semi-transparent"
              label={t('view_profile')}
            />
          </ObjectLink>
        ) : (
          actions
        )
      }
      to={!noLinkOnClick && to}
      href={!noLinkOnClick && href}
      onClick={onClick}
      moreList={moreList}
      isBlocked={isBlocked}
      undoBlock={undoBlock}
      remove={remove}
      pageRole={pageRole}
      ownerId={ownerId}
      onClickPosts={!item.isPending ? onClickPosts : undefined}
      isPrivateAccount={isPrivateAccount}
      isLoading={isLoading}
    />
  );
};

export default UnitSectionItem;
