import classes from './api-hook/styles.module.scss';

import React from 'react';
import {
  schedulesApi,
  schedulesEventValues,
  useReactMutation,
  useTranslation,
  useUpdateInfinityData,
} from '@lobox/utils';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import useToast from '@lobox/uikit/Toast/useToast';
import type { SchedulesEventTypes } from '@lobox/utils';
import { useSetEvents } from '@/shared/hooks/useSetEvents';
import getSchedulesSectionsQueryKey from '../utils/getSchedulesSectionsQueryKey';
import useSchedulesEvent from './useSchedulesEvent';

type UseDeleteNotificationProps = {
  schedulesEventType: SchedulesEventTypes;
  eventId: string;
  eventTitle: string;
  onSuccess: (data: any) => void;
};
const useDeleteScheduleEvent = (
  schedulesEventType: SchedulesEventTypes,
  eventId: string
) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { openConfirmDialog } = useOpenConfirm();

  const { mutate } = useReactMutation({
    apiFunc: schedulesApi.deleteEvent,
  });
  const { pastQueryKey, upComingQueryKey } =
    getSchedulesSectionsQueryKey(schedulesEventType);
  const { fetchEvents } = useSetEvents();
  const { remove: removeFromPast } = useUpdateInfinityData(pastQueryKey);
  const { remove: removeFromUpcoming } =
    useUpdateInfinityData(upComingQueryKey);
  const { closeHandler } = useSchedulesEvent();

  const deleteMsgs = {
    [schedulesEventValues.MEETING]: t('meeting_deleted_succ'),
    [schedulesEventValues.REMINDER]: t('reminder_deleted_succ'),
    [schedulesEventValues.TASK]: t('task_deleted_succ'),
  };
  const deleteConfirmTitles = {
    [schedulesEventValues.MEETING]: t('delete_meeting'),
    [schedulesEventValues.REMINDER]: t('delete_reminder'),
    [schedulesEventValues.TASK]: t('delete_task'),
  };
  const names = {
    [schedulesEventValues.MEETING]: t('meeting_s'),
    [schedulesEventValues.REMINDER]: t('reminder_s'),
    [schedulesEventValues.TASK]: t('task_s'),
  };

  const onDeletionSuccessHandler = () => {
    closeHandler();
    removeFromPast(eventId);
    removeFromUpcoming(eventId);
    fetchEvents();
  };

  const deleteHandler = ({
    schedulesEventType,
    eventId,
    eventTitle,
    onSuccess,
  }: UseDeleteNotificationProps) => {
    openConfirmDialog({
      title: deleteConfirmTitles[schedulesEventType],
      message: (
        <Flex className={classes.messageWrap}>
          <Typography>{t('r_y_s_w_r')}</Typography>
          <Typography ml={3} mr={3} font="bold">
            {eventTitle}
          </Typography>
          <Typography>{names[schedulesEventType]}</Typography>
        </Flex>
      ),
      confirmButtonText: t('remove'),
      cancelButtonText: t('cancel'),
      isAjaxCall: true,
      apiProps: {
        func: mutate,
        variables: { eventId, schedulesEventType },
        onSuccess: (data: any) => {
          toast({
            type: 'success',
            icon: 'check-circle',
            message: deleteMsgs[schedulesEventType],
          });
          onSuccess(data);
        },
      },
    });
  };
  return { deleteHandler, onDeletionSuccessHandler };
};

export default useDeleteScheduleEvent;
