import {
  useScheduleDispatch,
  useScheduleState,
} from '@/shared/contexts/Schedule/schedule.provider';
import useGetEventDetails from '@shared/hooks/useGetEventDetails';
import {
  schedulesDb,
  schedulesEventValues,
  useGlobalDispatch,
  useUrlState,
} from '@lobox/utils';
import { Time } from '@shared/utils/Time';
import { checkIfAllDay } from '@shared/utils/checkIfAllDay';
import { focusOnRichText } from '@shared/utils/focusOnRichText';
import type { QueryObserverResult } from '@tanstack/react-query';
import type { ISchedulesEvent, SchedulesEventTypes } from '@lobox/utils';
import type { EventCreationFromCalendarInitialData } from '@shared/types/schedules';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import { useMemo } from 'react';

type UseschedulesEventType = {
  schedulesEventType: SchedulesEventTypes;
  event: ISchedulesEvent;
  eventId: string;
  isCreationMode: boolean;
  queryResult: QueryObserverResult<ISchedulesEvent>;
  closeHandler: () => void;
  backHandler: () => void;
  hasEditAccess: boolean;
  isProfileTab: boolean;
  creationInitialData?: EventCreationFromCalendarInitialData;
  handleEdit: (focusOnEditor?: boolean) => void;
};

const useSchedulesEvent = (): UseschedulesEventType => {
  const { authUser } = useGetAppObject();
  const { replace, state } = useUrlState(
    {} as {
      defaultSchedulesEventType: Event;
      schedulesEventType: Event;
      eventId: string;
    }
  );
  const globalDispatch = useGlobalDispatch();
  const scheduleDispatch = useScheduleDispatch();
  const {
    isProfileTab,
    schedulesEventType,
    eventId,
    creationInitialData,
    ...rest
  } = useScheduleState('scheduleCreationModalData');

  const isCreationMode = !eventId;
  const queryResult = useGetEventDetails({
    type: schedulesEventType,
    id: eventId,
    enabled: !!eventId,
  });
  const event = queryResult.data;
  if (event) {
    let startProp = 'start';
    let endProp = 'end';

    if (event?.schedulesEventType === 'HOLIDAY') {
      startProp = 'date';
      endProp = 'date';
    }

    event.startTime = Time.convertBackFormatToFront(event[startProp]);
    event.endTime = Time.convertBackFormatToFront(event[endProp]);

    if (checkIfAllDay({ ...event, type: event?.schedulesEventType })) {
      event.startTime = Time.getTimeWithOffset(
        Time.convertBackFormatToFront(event?.[startProp]),
        -Time.getCurrentTimezoneOffset()
      );
    }
  }

  const resetUrlState = () => {
    if (state?.defaultSchedulesEventType) {
      replace((prev) => ({ ...prev, defaultSchedulesEventType: undefined }));
    }
  };
  const closeHandler = () => {
    scheduleDispatch({ type: 'RESET_URL' });

    globalDispatch({
      type: 'SET_SHOW_RIGHT_PANEL',
      payload: false,
    });
  };
  const backHandler = () => {
    resetUrlState();
    scheduleDispatch({
      type: 'SET_SCHEDULE_CREATION_MODAL_DATA',
      payload: {
        ...rest,
        visibleForm: false,
        schedulesEventType: event.schedulesEventType,
        eventId: event.id,
      },
    });
  };

  const hasEditAccess = useMemo(
    () =>
      event?.currentUserIsCreator ||
      (event?.schedulesEventType === schedulesEventValues.MEETING
        ? event?.attendees?.some(
            (attendee) =>
              attendee?.id === authUser?.id &&
              attendee?.permissions?.includes(
                schedulesDb.permissions.MODIFY_MEETING
              )
          )
        : event?.schedulesEventType === schedulesEventValues.TASK
        ? event?.collaborators?.some(
            (collaborator) =>
              collaborator?.id === authUser?.id &&
              collaborator?.permissions?.includes(
                schedulesDb.permissions.MODIFY_TASK
              )
          )
        : false),
    [event?.id]
  );
  const handleEdit = (focusOnEditor: false) => {
    if (!hasEditAccess) return;

    scheduleDispatch({
      type: 'SET_SCHEDULE_CREATION_MODAL_DATA',
      payload: {
        creationInitialData,
        ...rest,
        isProfileTab,
        visibleForm: true,
        schedulesEventType,
        eventId,
      },
    });
    if (focusOnEditor) {
      setTimeout(focusOnRichText, 1000);
    }
  };

  return {
    schedulesEventType,
    eventId,
    isCreationMode,
    event,
    queryResult,
    closeHandler,
    backHandler,
    hasEditAccess,
    creationInitialData,
    isProfileTab,
    handleEdit,
  };
};

export default useSchedulesEvent;
