import React from 'react';
import {
  PeopleFollowResponseType,
  PeopleType,
  getPageDetail,
  getUser,
  useReactQuery,
  useTranslation,
  useUpdateQueryData,
} from '@lobox/utils';
import {
  FollowersItem,
  PendingRequestItem,
} from '@shared/components/molecules';
import { followStatus as fS } from '@shared/constants/enums';
import useGetAppObject from '@shared/hooks/useGetAppObject';

export const useGetUserData = (
  username: string,
  isPage?: boolean,
  useId?: boolean
): ReturnType<typeof useReactQuery> => {
  const queryKey = username;
  const { t } = useTranslation();

  const { data: userOrPage = {}, ...rest } = useReactQuery<any>({
    action: {
      key: username,
      apiFunc: isPage ? getPageDetail : getUser,
      params: {
        containsCroppedHeaderImageLink: true,
        ...(!useId && { username }),
        ...(useId && isPage && { pageId: username }),
        ...(useId && !isPage && { userId: username }),
      },
    },
    config: {
      enabled: !!username,
    },
  });
  const { replace } = useUpdateQueryData(queryKey);
  const { reFetchAppObject } = useGetAppObject();
  const success =
    (item: PeopleType, follow: boolean) =>
    ({ followStatus }: PeopleFollowResponseType) => {
      replace({ network: { ...item?.network, follow, followStatus } });
      reFetchAppObject();
    };

  const actions = (item: any) =>
    item?.network?.followStatus === fS.PENDING ? (
      <PendingRequestItem object={item} onSuccess={success(item, false)} />
    ) : (
      <FollowersItem
        object={{
          isPage,
          id: item?.id,
          name:
            item?.title || (item?.name && item?.surname)
              ? `${item?.name} ${item?.surname}`
              : undefined,
          username: item?.username as string,
          croppedImageUrl: item?.croppedImageUrl,
          fullName:
            item?.title || (item?.name && item?.surname)
              ? `${item?.name} ${item?.surname}`
              : undefined,
          isFollowed: item?.network?.follow,
          back: item?.back,
        }}
        onSuccess={success(item, !item?.network?.follow)}
      />
    );
  const transform = (item: any): any => ({
    id: item?.id,
    image: item?.croppedImageUrl,
    title:
      item?.name && item?.surname
        ? `${item?.name} ${item?.surname}`
        : undefined,
    to: `/${item?.username}`,
    postsCounter:
      item?.personNetworkModel?.postsCounter || item?.network?.postsCounter,
    croppedHeaderImageLink: item?.croppedHeaderImageLink || null,
    locationTitle: item?.location?.title,
    subtitle: `@${item?.username}`,
    description: item?.occupation?.label,
    followers:
      item?.personNetworkModel?.followersCounter ||
      item?.network?.followersCounter,
    following:
      item?.personNetworkModel?.followingsCounter ||
      item?.network?.followingsCounter,
    moreList: [],
    isBlocked: false,
    username: item?.username,
    actions: actions(item),
  });
  const transformPage = (item: any): any => ({
    id: item?.id,
    image: item?.croppedImageUrl,
    title: item?.title,
    to: `/${item?.username}`,
    postsCounter:
      item?.pageNetworkModel?.postsCounter || item?.network?.postsCounter,
    croppedHeaderImageLink: item?.croppedHeaderImageUrl || null,
    locationTitle: item?.locations?.[0]?.title || item?.locations?.[0]?.name,
    subtitle: `@${item?.username}`,
    description: t(item?.category?.label),
    followers:
      item?.pageNetworkModel?.followersCounter ||
      item?.network?.followersCounter,
    following:
      item?.pageNetworkModel?.followingsCounter ||
      item?.network?.followingsCounter,
    moreList: [],
    isBlocked: false,
    username: item?.username,
    actions: actions(item),
    avatarProps: {
      isCompany: true,
    },
  });

  const data = isPage ? transformPage(userOrPage) : transform(userOrPage);

  return { data: data || {}, ...rest };
};
