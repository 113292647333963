import { dayjs } from '@lobox/utils';
import { sortBy } from 'lodash';
import type { Data } from '../types/schedules';
import { Time } from './Time';
import { checkIfAllDay } from './checkIfAllDay';
import { makeUniqueIdForEvent } from './makeUniqueIdForEvent';

export function normalizeFetchedEvents(res: any): Data['savedEvents'] {
  const _data: Data['savedEvents'] = res
    ?.map((item: any) => {
      const isAllDay = checkIfAllDay(item);
      if (!item?.end) item.end = item?.start;

      return {
        ...item,
        startTime: isAllDay
          ? Time.getTimeWithOffset(
              Time.convertBackFormatToFront(item?.start),
              -Time.getCurrentTimezoneOffset()
            )
          : Time.convertBackFormatToFront(item?.start),
        endTime: isAllDay
          ? Time.getTimeWithOffset(
              Time.convertBackFormatToFront(item?.end),
              -Time.getCurrentTimezoneOffset()
            )
          : Time.convertBackFormatToFront(item?.end),
        isAllDay,
        uniqueEventId: makeUniqueIdForEvent(item),
      };
    })
    .filter((item: any) => dayjs(item?.endTime).diff(item?.startTime) >= 0);

  const now = Time.getToday();
  return sortBy(_data, (item) => now.diff(item?.startTime))?.reverse();
}
