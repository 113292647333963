import { useCallback, useEffect, useMemo } from 'react';
import {
  dayjs,
  routeNames,
  schedulesEventValues,
  useGlobalDispatch,
  useHistory,
  useTranslation,
  useLocation,
} from '@lobox/utils';
import {
  useScheduleDispatch,
  useScheduleState,
} from '@/shared/contexts/Schedule/schedule.provider';
import type { MouseEvent } from 'react';
import { Time } from '../utils/Time';
import {
  useSchedulesCalendarDispatch,
  useSchedulesCalendarState,
} from '@shared/utils/schedulesCalendar.provider';
import type {
  CalendarBoardType,
  Data,
  DateType,
  DayUnit,
  EventCreationFromCalendarInitialData,
  EventType,
} from '../types/schedules';

type Rtype = {
  data: Data;
  setViewDateToToday: () => void;
  setViewDate: (date: DateType) => void;
  decrementViewDateBy: (unit: DayUnit) => any;
  incrementViewDateBy: (unit: DayUnit) => any;
  calendarBoardType: CalendarBoardType;
  handleIncrement: () => any;
  handleDecrement: () => any;
  setFilteredEvents: (events: Data['filteredEvents']) => any;
  setCalendarBoardType: (boardType: CalendarBoardType) => any;
  openCreateEventWithDate: (
    date?: DateType,
    initialData?: EventCreationFromCalendarInitialData
  ) => any;
  handleEventClick: (
    e: MouseEvent<HTMLDivElement, MouseEvent>,
    event: EventType
  ) => any;
  setEvents: (events: Data['savedEvents']) => any;
};

export function useSchedulesCalendar(): Rtype {
  const { t } = useTranslation();
  const data: Data = useSchedulesCalendarState('data');

  const dispatch = useSchedulesCalendarDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const globalDispatch = useGlobalDispatch();
  const scheduleDispatch = useScheduleDispatch();
  const { visibleForm } = useScheduleState('scheduleCreationModalData');
  const calendarBoardType: CalendarBoardType = useMemo(
    () =>
      (['year', 'month', 'day', 'week'] as CalendarBoardType[])?.reduce(
        (acc, cur) => (pathname?.includes(cur) ? cur : acc),
        undefined
      ),
    [pathname]
  );

  const setViewDateToToday = useCallback(() => {
    dispatch({
      type: 'SET_VIEW_DATE',
      payload: Time.getToday(),
    });
  }, [dispatch]);

  const setViewDate = useCallback(
    (date: DateType) => {
      dispatch({
        type: 'SET_VIEW_DATE',
        payload: date,
      });
    },
    [dispatch]
  );
  const decrementViewDateBy = useCallback(
    (unit: DayUnit) => {
      dispatch({
        type: 'SET_VIEW_DATE',
        payload: Time.decrementDateBy(data?.viewDate?.start || dayjs(), unit),
      });
    },
    [dispatch, data]
  );
  const incrementViewDateBy = useCallback(
    (unit: DayUnit) => {
      dispatch({
        type: 'SET_VIEW_DATE',
        payload: Time.incrementDateBy(data?.viewDate?.start || dayjs(), unit),
      });
    },
    [dispatch, data]
  );

  const handleIncrement = useCallback(() => {
    incrementViewDateBy(calendarBoardType);
  }, [calendarBoardType, incrementViewDateBy]);

  const handleDecrement = useCallback(() => {
    decrementViewDateBy(calendarBoardType);
  }, [calendarBoardType, decrementViewDateBy]);

  const setFilteredEvents = useCallback(
    (events: Data['filteredEvents']) => {
      dispatch({
        type: 'SET_FILTERED_EVENTS',
        payload: events,
      });
    },
    [dispatch]
  );
  const setCalendarBoardType = useCallback(
    (boardType: CalendarBoardType) => {
      history.push(boardType);
    },
    [history]
  );

  const openCreateEventWithDate = useCallback(
    (
      date: DateType = Time.incrementDateBy(
        dayjs(Time.getToday()).startOf('hour'),
        'hour',
        1
      ),
      initialData: EventCreationFromCalendarInitialData = {}
    ) => {
      // const end = Time.incrementDateBy(date, 'hour', 1 / 4);
      const payload: EventCreationFromCalendarInitialData = {
        startDate: Time.getFormDate(date),
        startTime: Time.getFormTime(date),
        // endDate: Time.getFormDate(end),
        // endTime: Time.getFormTime(end),
        schedulesEventType: pathname?.includes(routeNames.schedulesReminders)
          ? schedulesEventValues.REMINDER
          : pathname?.includes(routeNames.schedulesTasks)
          ? schedulesEventValues.TASK
          : schedulesEventValues.MEETING,
        ...initialData,
      };
      globalDispatch({
        type: 'SET_SHOW_RIGHT_PANEL',
        payload: true,
      });

      scheduleDispatch({
        type: 'SET_SCHEDULE_CREATION_MODAL_DATA',
        payload: {
          visibleForm: true,
          schedulesEventType:
            payload?.schedulesEventType || schedulesEventValues.MEETING,
          creationInitialData: payload,
        },
      });
      dispatch({
        type: 'SET_TEMPORARY_EVENT',
        payload: {
          id: `${Number.MAX_SAFE_INTEGER}`,
          title: t('no_title'),
          type: 'TEMPORARY',
          startTime: date,
        },
      });
    },
    [scheduleDispatch, globalDispatch, dispatch, t]
  );

  const handleEventClick = useCallback(
    (
      e: MouseEvent<HTMLDivElement, MouseEvent>,
      event: EventType & { isFromNotification?: boolean }
    ) => {
      if (e) e?.stopPropagation();
      if (event?.type === 'TEMPORARY') return;
      globalDispatch({
        type: 'SET_SHOW_RIGHT_PANEL',
        payload: true,
      });
      scheduleDispatch({
        type: 'SET_SCHEDULE_CREATION_MODAL_DATA',
        payload: {
          visibleForm: false,
          schedulesEventType: event?.type,
          eventId: event?.id,
          isFromNotification: event?.isFromNotification,
        },
      });
    },
    [scheduleDispatch, globalDispatch]
  );

  const setEvents = useCallback(
    (events: Data['savedEvents']) => {
      dispatch({
        type: 'SET_EVENTS',
        payload: events,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    // Ensures that upon closing creation event form, the temporary event is deleted on the calendar board
    if (visibleForm) return;
    dispatch({
      type: 'SET_TEMPORARY_EVENT',
      payload: {},
    });
  }, [visibleForm]);

  return {
    data,
    setViewDate,
    setViewDateToToday,
    decrementViewDateBy,
    incrementViewDateBy,
    calendarBoardType,
    handleDecrement,
    handleIncrement,
    setFilteredEvents,
    setCalendarBoardType,
    openCreateEventWithDate,
    handleEventClick,
    setEvents,
  };
}
