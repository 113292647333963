import { useCallback } from 'react';
import { schedulesApi, useAuthState, useReactQuery } from '@lobox/utils';
import { Time } from '../utils/Time';
import { useSchedulesCalendar } from './useSchedulesCalendar';
import { allEventTypes } from '@shared/utils/consts';
import type { CalendarBoardType } from '../types/schedules';
import { normalizeFetchedEvents } from '@shared/utils/normalizeFetchedEvents';

type Rtype = {
  fetchEvents: () => void;
};

export function useSetEvents(enabled = true): Rtype {
  const { data, calendarBoardType, setEvents } = useSchedulesCalendar();
  const isLoggedIn = useAuthState('isLoggedIn');

  const date = data?.viewDate?.start;
  const startDate =
    calendarBoardType === 'year'
      ? Time.getStartingDateOfYear(date)
      : Time.getStartingDateOfMonth(date);

  const dataType: CalendarBoardType =
    calendarBoardType === 'year' ? 'year' : 'month';

  const eventsQueryKey = ['EVENTS', dataType, startDate]?.join(',');

  const { refetch } = useReactQuery({
    action: {
      apiFunc: schedulesApi.getCalendarEvents,
      key: eventsQueryKey,
      params: {
        fromDateTime: Time.convertFrontFormatToBack(
          Time.decrementDateBy(startDate, dataType, 1)
        ),
        toDateTime: Time.convertFrontFormatToBack(
          Time.incrementDateBy(startDate, dataType, 2)
        ),
        types: [...allEventTypes]?.join(','),
      },
    },
    config: {
      onSuccess: (res = []) => {
        const tempEvent = data?.savedEvents?.find(
          (item) => item?.type === 'TEMPORARY'
        );
        const _events = tempEvent
          ? [tempEvent, ...normalizeFetchedEvents(res)]
          : normalizeFetchedEvents(res);
        setEvents(_events);
      },
      enabled: isLoggedIn && enabled,
    },
  });

  const fetchEvents = useCallback(refetch, [refetch]);
  return { fetchEvents };
}
