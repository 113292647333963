'use client';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

export function usePrefetchRoutes(routes: Array<string>) {
  const router = useRouter();
  useEffect(() => {
    routes.forEach((route) => {
      router.prefetch(route);
    });
  }, [routes?.join('')]);
}
