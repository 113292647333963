import { useUrlState } from '@lobox/utils';
import { scheduleReducer, initialState } from './schedule.reducer';

export const useScheduleState = (propertyInState: string) => {
  const { state } = useUrlState({} as typeof initialState);
  return state?.[propertyInState] || {};
};
export const useScheduleDispatch: () => (
  action: Parameters<typeof scheduleReducer>['1']
) => void = () => {
  const { replace } = useUrlState({} as typeof initialState);
  function _dispatch(action: Parameters<typeof scheduleReducer>['1']) {
    replace((prev) => scheduleReducer(prev, action));
  }
  return _dispatch;
};
