import { dayjs } from '@lobox/utils';
import type { Data, DateType, DayUnit, EventType } from '../types/schedules';

export class Time {
  static isBetween(
    time: DateType,
    start: DateType,
    end: DateType,
    noEqual = false,
    noEqualOnlyForEnd = false
  ): boolean {
    if (!time || !start || !end) return false;
    if (noEqual)
      return dayjs(start).diff(time) < 0 && dayjs(end).diff(time) > 0;
    if (noEqualOnlyForEnd)
      return dayjs(start).diff(time) <= 0 && dayjs(end).diff(time) > 0;
    return dayjs(start).diff(time) <= 0 && dayjs(end).diff(time) >= 0;
  }

  static isAfterNow(time: DateType): boolean {
    const now = Time.getToday();
    return dayjs(time).isAfter(now);
  }

  static isBeforeNow(time: DateType, unit?: DayUnit): boolean {
    const now = Time.getToday();
    return dayjs(time).isBefore(now, unit);
  }

  static getStartingDateOfYear(time: DateType): DateType {
    return dayjs(time).startOf('year');
  }

  static getStartingDateOfMonth(time: DateType): DateType {
    return dayjs(time).startOf('month');
  }

  static getStartingDateOfWeek(time: DateType): DateType {
    return dayjs(time).startOf('week');
  }

  static getStartingDateOfDay(time: DateType): DateType {
    return dayjs(time).startOf('day');
  }

  static getEndingDateOfDay(time: DateType): DateType {
    return dayjs(time).endOf('day');
  }

  static areSameDay(
    time1: DateType,
    time2: DateType,
    unit: Parameters<DateType['isSame']>['1'] = 'day'
  ): boolean {
    return time1.isSame(time2, unit);
  }

  static getToday(...args: Parameters<typeof dayjs>): DateType {
    return dayjs(...args);
  }

  static incrementDateBy(date: DateType, unit: DayUnit, amount = 1): DateType {
    return dayjs(date).add(amount, unit);
  }

  static decrementDateBy(date: DateType, unit: DayUnit, amount = 1): DateType {
    return dayjs(date).subtract(amount, unit);
  }

  static getViewDateDetails(date: DateType): Data['viewDate'] {
    return {
      start: date,
      dayNumberInMonth: dayjs(date).get('date'),
      dayOfWeek: dayjs(date).format('dddd'),
      dayOfWeekShortenedLabel: dayjs(date)
        .format('dddd')
        .toUpperCase()
        ?.slice(0, 3),
      monthAndYearLabel: dayjs(date).format('MMMM YYYY'),
    };
  }

  static getMonthLabel(date: DateType): string {
    return dayjs(date).format('MMMM');
  }

  static getTimeLabel(
    startDate: DateType,
    endDate: DateType,
    extendsToOtherDays: boolean
  ): string {
    if (extendsToOtherDays) {
      return `${dayjs(startDate).format('D MMM h:mma')} | ${dayjs(
        endDate
      ).format('D MMM h:mma')}`;
    }

    if (!endDate) {
      return dayjs(startDate).format('h:mma');
    }
    return `${dayjs(startDate).format('h:mma')} | ${dayjs(endDate).format(
      'h:mma'
    )}`;
  }

  static getThreeLetteredWeekDay(date: DateType): string {
    return dayjs(date).format('dddd').slice(0, 3).toUpperCase();
  }

  static convertFrontFormatToBack(date: DateType): string {
    const str = new Date(date?.format()).toISOString();
    return str.substring(0, str.length - 5);
  }

  static convertBackFormatToFront(stringDate: string): DateType {
    let date;
    try {
      date = dayjs(`${stringDate}Z`);
    } catch (err) {
      console.log(err);
      date = undefined;
    }
    return date;
  }

  /**
   * @param stringDate Format example: 2023-06-06T00:00:00
   * @param time Format example: 03:00
   * @param offset In milliseconds
   * @returns Format example: 2023-06-06T03:00:00
   */
  static getBackendDateTimeFromDateAndTime(
    stringDate: string,
    time = '00:00',
    offset = Time.getCurrentTimezoneOffset()
  ): string {
    if (!time) time = '00:00';
    const str = stringDate?.replace('T00:00', `T${time}`);
    return Time.convertFrontFormatToBack(
      Time.convertBackFormatToFront(str)?.subtract(offset, 'millisecond')
    );
  }

  static getFormTime(
    date: DateType,
    timezoneOffset = 0
  ): { label: string; value: string } {
    return {
      label: dayjs(Time.getTimeWithOffset(date, timezoneOffset))?.format(
        'HH:mm'
      ),
      value: dayjs(Time.getTimeWithOffset(date, timezoneOffset))?.format(
        'HH:mm'
      ),
    };
  }

  static getFormDate(date: DateType): string {
    return dayjs(date)?.format('YYYY-MM-DDT00:00:00');
  }

  static getCurrentTimezoneOffset(): number {
    return -1 * new Date()?.getTimezoneOffset() * 60 * 1000;
  }

  static getTimeZoneOffsetLabel(): string {
    const hours = Time.getCurrentTimezoneOffset() / (1000 * 3600);
    const WholeHours = Math.floor(hours);
    const minutes = hours - WholeHours;
    const _minutes = (minutes * 60).toFixed(0);
    const hasMinutes = !!_minutes;
    const isPlus = hours > 0;
    return `GMT${isPlus ? '+' : '-'}${WholeHours}${
      hasMinutes ? ':'.concat(_minutes) : ''
    }`;
  }

  static getTimeWithOffset(time: DateType, offset: number): DateType {
    return dayjs(time).add(offset, 'millisecond');
  }

  static getTimezoneCode(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  static getIsEventEntirelyCoveringRange(
    event: EventType,
    startTime: DateType,
    endTime: DateType
  ): boolean {
    return (
      Time.isBetween(startTime, event?.startTime, event?.endTime, true) &&
      Time.isBetween(endTime, event?.startTime, event?.endTime, true)
    );
  }
}
