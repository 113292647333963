import {
  QueryKeys,
  schedulesApi,
  schedulesEventValues,
  useReactQuery,
} from '@lobox/utils';
import { useQuery, type QueryObserverResult } from '@tanstack/react-query';
import type { ISchedulesEvent } from '@lobox/utils';
import { useMemo } from 'react';

interface Params {
  type: string;
  id: string;
  enabled: boolean;
}
const apis = {
  [schedulesEventValues.MEETING]: schedulesApi.getMeetingDetails,
  [schedulesEventValues.REMINDER]: schedulesApi.getReminderDetails,
  [schedulesEventValues.TASK]: schedulesApi.getTaskDetails,
  [schedulesEventValues.BIRTHDAY]: schedulesApi.getBirthdayDetails,
  [schedulesEventValues.HOLIDAY]: schedulesApi.getHolidayDetails,
};

export const useGetEventDetails = (
  params: Params
): QueryObserverResult<ISchedulesEvent, unknown> => {
  const { enabled, id, type } = params;

  const api = useMemo(() => apis[type], [type]);

  const queryResult = useReactQuery<ISchedulesEvent>({
    action: {
      key: [QueryKeys.schedulesEvents?.[0], type, id],
      apiFunc: api,
      params: { id },
    },
    config: {
      enabled,
    },
  });

  // const queryResult = useQuery({
  //   queryKey: [QueryKeys.schedulesEvents?.[0], type, id],
  //   queryFn: () => api({ params }),
  //       refetchOnWindowFocus: false,
  //   refetchInterval: 1000 * 60 * 10,
  //   enabled,
  // });

  return queryResult;
};

export default useGetEventDetails;
