import { Time } from './Time';
import type { Event } from '../types/schedules';

export const COLORS: Record<Event, string> = {
  MEETING: 'cornflowerBlue',
  BIRTHDAY: 'heliotrope',
  HOLIDAY: 'lightBrown',
  REMINDER: 'darkTangerine',
  TASK: 'darkError',
  TEMPORARY: 'brand',
};

export const COLORS_DARK: typeof COLORS = Object.keys(COLORS).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: COLORS?.[cur]?.concat(cur === 'TEMPORARY' ? '' : 'Dark'),
  }),
  COLORS
);

export const EVENT_LABELS: Record<Exclude<Event, 'TEMPORARY'>, string> = {
  MEETING: 'e_meetings',
  BIRTHDAY: 'e_birthdays',
  HOLIDAY: 'e_public_holidays',
  REMINDER: 'e_reminders',
  TASK: 'e_tasks',
};

export const allEventTypes = <Event[]>Object.keys(EVENT_LABELS);

export const WEEK_DAY_THREE_LETTERED_LABELS = Array(7)
  .fill(null)
  .map((_, index) =>
    Time.getThreeLetteredWeekDay(
      Time.incrementDateBy(
        Time.getStartingDateOfWeek(Time.getToday()),
        'day',
        index
      )
    )
  );

export const MEDIA_BOX_SIZE = {
  WIDTH: 650,
  HEIGHT: 650,
};
