import { useCreateContext } from '@lobox/utils';
import {
  initialState,
  schedulesCalendarReducer,
} from './schedulesCalendar.reducer';
import type { ActionType } from './schedulesCalendar.reducer';

const [state, dispatch, provider] = useCreateContext(
  schedulesCalendarReducer,
  initialState
);
export const useSchedulesCalendarState = state;
export const useSchedulesCalendarDispatch = dispatch as () => (
  action: ActionType
) => void;
export const SchedulesCalendarProvider = provider;
