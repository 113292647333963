import classes from './AvatarsCard.component.module.scss';

import React, { useMemo } from 'react';
import Avatar from '@lobox/uikit/Avatar';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Typography from '@lobox/uikit/Typography';
import type { FC } from 'react';
import type { AvatarProps } from '@lobox/uikit/Avatar';
import { EntitiyPopper } from '../EntityPopper/EntitiyPopper';
import { usePrefetchRoutes } from '@shared/hooks/usePrefetchRoutes';

export interface AvatarsCardProps {
  className?: string;
  avatars: Array<{
    id: string;
    croppedImageUrl: string;
    isPage?: boolean;
    username?: string;
  }>;
  avatarProps?: AvatarProps;
  onClick?: () => void;
  text?: string;
}

const AvatarsCard: FC<AvatarsCardProps> = (props) => {
  const {
    avatars,
    className,
    avatarProps,
    onClick = () => {
      // pass
    },
    text,
  } = props;

  const sortedData = useMemo(
    () =>
      avatars?.sort((a, b) => (a.isPage === b.isPage ? 0 : a.isPage ? 1 : -1)),
    [avatars]
  );

  if (sortedData) {
    usePrefetchRoutes(sortedData?.map(({ username }) => `/${username}`));
  }

  return (
    <BaseButton
      onClick={onClick}
      className={cnj(classes.avatarsRoot, className)}
    >
      {sortedData?.map(({ isPage, id, croppedImageUrl, username }) => (
        <EntitiyPopper
          isPage={isPage}
          username={username}
          classNames={{ buttonWrapper: classes.avatar }}
        >
          <Avatar
            bordered
            key={id}
            className={cnj(
              classes.avatar,
              avatarProps?.size === 'xs' && classes.xsAvatar,
              avatarProps?.size === 'xxs' && classes.xxsAvatar
            )}
            size="sm"
            imgSrc={croppedImageUrl}
            isCompany={isPage}
            {...avatarProps}
          />
        </EntitiyPopper>
      ))}
      {text ? (
        <Typography
          ml={4}
          color="border"
          font="500"
          size={12}
          height={18}
          className={classes.text}
        >
          {text}
        </Typography>
      ) : null}
    </BaseButton>
  );
};

export default AvatarsCard;
