import type { SchedulesEventTypes } from '@lobox/utils';

export const initialState = {
  scheduleCreationModalData: {},
  shareAvailabilityRightSideModal: {
    isVisible: false,
  },
} as any;

type ActionType =
  | {
      type: 'SET_SCHEDULE_CREATION_MODAL_DATA';
      payload: {
        visibleForm?: boolean;
        schedulesEventType: SchedulesEventTypes;
        eventId?: string;
        isFromNotification?: boolean;
      };
    }
  | {
      type: 'SET_FILTER_WRAPPER_REF';
      payload?: any;
    }
  | {
      type: 'SET_SHARE_AVAILABILITY_RIGHT_SIDE_MODAL';
      payload: {
        isVisible: boolean;
      };
    }
  | { type: 'RESET_URL' };

type StateType = typeof initialState;

export function scheduleReducer(
  state: StateType,
  action: ActionType
): StateType {
  switch (action.type) {
    case 'SET_SCHEDULE_CREATION_MODAL_DATA':
      return {
        ...state,
        scheduleCreationModalData: action.payload,
      };

    case 'SET_SHARE_AVAILABILITY_RIGHT_SIDE_MODAL':
      return {
        ...state,
        shareAvailabilityRightSideModal: action?.payload,
      };

    case 'RESET_URL': {
      return {};
    }

    default: {
      throw new Error(`Unsupported action type at Schedule Reducer`);
    }
  }
}
