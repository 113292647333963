import Flex from '@lobox/uikit/Flex';
import PopperMenu from '@lobox/uikit/PopperMenu';
import React, { ComponentProps } from 'react';
import { UnitSectionItemWithData } from './UnitSectionItemWithData';
import useMedia from '@lobox/uikit/utils/useMedia';

type Props = {
  username: string;
  classNames?: {
    popper?: string;
    menu?: string;
    buttonWrapper?: string;
  };
  isPage?: boolean;
  popperMenuProps?: any;
  placement?: ComponentProps<typeof PopperMenu>['placement'];
  useId?: boolean;
  fallbackData?: {
    name?: string;
    usernameAtSign?: string;
    icon?: string;
    isPage?: boolean;
  };
};
export const EntitiyPopper: React.FC<Props> = ({
  username,
  classNames,
  isPage = false,
  children,
  placement = 'left-start',
  popperMenuProps = {},
  useId = false,
  fallbackData,
}) => {
  const { isTabletAndLess } = useMedia();

  return isTabletAndLess ? (
    children
  ) : (
    <PopperMenu
      showWithHover
      hoverDelay={300}
      menuClassName={classNames?.menu}
      placement={placement}
      closeOnScroll
      buttonComponent={
        <Flex className={classNames?.buttonWrapper}>{children}</Flex>
      }
      {...popperMenuProps}
    >
      <UnitSectionItemWithData
        username={username}
        isPage={isPage}
        className={classNames?.popper}
        useId={useId}
        fallbackData={fallbackData}
      />
    </PopperMenu>
  );
};
