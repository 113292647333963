import { dayjs } from '@lobox/utils';
import type { Data, DateType, EventType } from '../types/schedules';
import { Time } from './Time';
import { allEventTypes } from './consts';

export const initialState: {
  data: Data;
} = {
  data: {
    filteredEvents: ['TEMPORARY', ...allEventTypes],
    savedEvents: [],
    viewDate: {
      start: dayjs(),
      dayNumberInMonth: dayjs().get('date'),
      dayOfWeek: dayjs().format('dddd'),
      dayOfWeekShortenedLabel: dayjs()
        .format('dddd')
        .toUpperCase()
        ?.slice(0, 3),
      monthAndYearLabel: dayjs().format('MMMM YYYY'),
    },
    isLoaded: false,
  },
};

export type ActionType =
  | {
      type: 'SET_EVENTS';
      payload?: Data['savedEvents'];
    }
  | {
      type: 'SET_VIEW_DATE';
      payload?: DateType;
    }
  | { type: 'SET_FILTERED_EVENTS'; payload?: Data['filteredEvents'] }
  | { type: 'SET_TEMPORARY_EVENT'; payload?: EventType };

type StateType = typeof initialState;

export function schedulesCalendarReducer(
  state: StateType,
  action: ActionType
): StateType {
  switch (action.type) {
    case 'SET_EVENTS':
      return {
        ...state,
        data: { ...state?.data, savedEvents: action.payload, isLoaded: true },
      };

    case 'SET_VIEW_DATE': {
      const time = action.payload;
      const viewDate = Time.getViewDateDetails(time);

      return {
        ...state,
        data: { ...state?.data, viewDate },
      };
    }
    case 'SET_FILTERED_EVENTS': {
      return {
        ...state,
        data: {
          ...state?.data,
          filteredEvents: ['TEMPORARY', ...action?.payload],
        },
      };
    }

    case 'SET_TEMPORARY_EVENT': {
      const events: Data['savedEvents'] = state?.data?.savedEvents?.filter(
        (item) => item?.type !== 'TEMPORARY'
      );
      if (action.payload?.type === 'TEMPORARY') {
        events.push(action?.payload);
      }
      return {
        ...state,
        data: { ...state?.data, savedEvents: events },
      };
    }
    default: {
      throw new Error(`Unsupported action type at Shedules Reducer`);
    }
  }
}
